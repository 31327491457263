@import "~filepond/dist/filepond.css";

.gallery {
    display: flex;
    flex-wrap: wrap;

    .gallery-item {
        width: 25%;
        padding: .25rem;
        height: 25vh;
        overflow: hidden;
        transition: opacity 0.4s;

        & > .image {
            position: relative;
            height: 100%;
            box-shadow: 0 2px 1px 0 rgb(0 0 0 / 10%);
            //box-shadow: -1px 2px 1px 1px rgb(0 0 0 / 10%);

            &:before, &:after {
                position: absolute;
                content: '';
                top: 0;
                left: 0;
                right: 0;
                bottom: 0;
                border: 5px solid #fff;
                border-radius: 3px;
                z-index: 1;
            }

            &:before {
                z-index: 2;
                background: rgba(0, 0, 0, 0);
                transition: all 200ms ease;
            }

            &:hover {
                &:before {
                    background: rgba(0, 0, 0, 0.3);
                }
            }

            & > img {
                max-width: 100%;
                height: 100%;
                object-fit: cover;
                object-position: center;
                border-radius: 3px;
            }

            & > .gallery-item-controls {
                display: block;
                list-style: none;
                position: absolute;
                top: 5px;
                right: -60px;
                padding: 0;
                z-index: 2;
                transition: all 200ms ease;
                margin-bottom: 0;

                & > li {
                    float: left;
                    background: #fff;
                    width: 1.5rem;
                    height: 1.5rem;
                    padding-left: 0.25rem;

                    &:hover {
                        background: #F5F5F5;
                    }

                    &:first-child {
                        border-bottom-left-radius: 5px;
                    }

                    & > .form-check {
                        & > .form-check-input {
                            border-radius: 0;
                            cursor: pointer;
                        }
                    }

                    & > span {
                        color: #dd0000;
                        font-size: 16px;
                        padding-top: 1px;
                        height: 100%;
                        display: block;
                        text-align: center;
                        margin-left: -0.25rem;
                    }
                }
            }
        }

        &.active > .image {
            box-shadow: 0 0 6px 0 rgba(51, 65, 78, 0.8);
        }

        &:hover,
        &.active {
            & > .image .gallery-item-controls {
                right: 5px;
            }
        }
    }
}

.gallery-filepond {
    &.filepond--root {
        height: 10rem;
        max-height: 10rem;
    }

    & > .filepond--panel-root {
        background-color: rgba(0, 0, 0, .1);
        border-radius: 5px;

        &:before {
            display: inline-block;
            position: absolute;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
            content: "";
            vertical-align: -.125em;
            background-image: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" fill="%23999" viewBox="0 0 16 16"><path fill-rule="evenodd" d="M4.406 1.342A5.53 5.53 0 0 1 8 0c2.69 0 4.923 2 5.166 4.579C14.758 4.804 16 6.137 16 7.773 16 9.569 14.502 11 12.687 11H10a.5.5 0 0 1 0-1h2.688C13.979 10 15 8.988 15 7.773c0-1.216-1.02-2.228-2.313-2.228h-.5v-.5C12.188 2.825 10.328 1 8 1a4.53 4.53 0 0 0-2.941 1.1c-.757.652-1.153 1.438-1.153 2.055v.448l-.445.049C2.064 4.805 1 5.952 1 7.318 1 8.785 2.23 10 3.781 10H6a.5.5 0 0 1 0 1H3.781C1.708 11 0 9.366 0 7.318c0-1.763 1.266-3.223 2.942-3.593.143-.863.698-1.723 1.464-2.383z"/><path fill-rule="evenodd" d="M7.646 4.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 5.707V14.5a.5.5 0 0 1-1 0V5.707L5.354 7.854a.5.5 0 1 1-.708-.708l3-3z"/></svg>');
            background-repeat: no-repeat;
            background-size: 5rem 5rem;
            background-position: center 95%;
        }

    }

    //.bi::before {
    //    display: inline-block;
    //    content: "";
    //    vertical-align: -.125em;
    //    background-image: url("data:image/svg+xml,<svg viewBox='0 0 16 16' fill='%23333' xmlns='http://www.w3.org/2000/svg'><path fill-rule='evenodd' d='M8 9.5a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3z' clip-rule='evenodd'/></svg>");
    //    background-repeat: no-repeat;
    //    background-size: 1rem 1rem;
    //}
}
