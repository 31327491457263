@import "~bootstrap/scss/bootstrap";
@import "~bootstrap-icons/font/bootstrap-icons";
@import "~filepond/dist/filepond.css";
@import "~filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
@import "gallery";

.filepond--item {
    width: calc(50% - .5rem);
}

body {
    font-family: 'Nunito', sans-serif;
    background: #f5f5f5 url('../images/bg.png') left top repeat
    // #f2f3f9
}

.gray-dark {
    color: $gray-800;
}

.dashboard-card {
    border-left-width: 0.25rem;

    .text-xs {
        font-size: .7rem;
    }
}

.login-page {
    display: flex;
    align-items: center;
    padding: 40px 0;
    background-color: #f5f5f5;
}

.form-login {
    width: 100%;
    max-width: 330px;
    padding: 15px;
    margin: auto;
    background: #fff;
    border: $border-width solid $border-color;
    border-radius: $border-radius;
}

/*
 * Sidebar
 */
.sidebar {
    position: fixed;
    top: 0;
    /* rtl:raw:
    right: 0;
    */
    bottom: 0;
    /* rtl:remove */
    left: 0;
    z-index: 100; /* Behind the navbar */
    padding: 48px 0 0; /* Height of navbar */
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);

    .nav-link {
        font-weight: 500;

        &:not(.disabled) {
            color: #333;
        }

        &:hover,
        &.active {
            color: #007bff;

            .bi {
                color: inherit;
            }
        }

        .bi {
            margin-right: 4px;
            color: #727272;
        }
    }

    .btn-toggle {
        display: inline-flex;
        padding: 0.5rem 1rem;

        &:before {
            width: 1.25em;
            line-height: 0;
            content: url('data:image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16"><path fill="none" stroke="rgba(0,0,0,.5)" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="m5 14 6-6-6-6"/></svg>');
            transition: transform .35s ease;
            transform-origin: .5em 50%;
            transform: translateX(-0.25rem);
        }

        &[aria-expanded="true"]:before {
            transform: rotate(90deg);
        }
    }
    .btn-toggle-nav a {
        display: inline-flex;
        padding: .1875rem .5rem;
        margin-top: .125rem;
        margin-left: 2rem;
        text-decoration: none;
    }

}

@media (max-width: 767.98px) {
    .sidebar {
        top: 5rem;
    }
}

.sidebar-sticky {
    position: relative;
    top: 0;
    height: calc(100vh - 48px);
    padding-top: .5rem;
    overflow-x: hidden;
    overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar-heading {
    font-size: .75rem;
    text-transform: uppercase;
}

/*
 * Navbar
 */
.navbar-brand {
    padding-top: .75rem;
    padding-bottom: .75rem;
    font-size: 1rem;
    background-color: rgba(0, 0, 0, .25);
    box-shadow: inset -1px 0 0 rgba(0, 0, 0, .25);
}

.navbar .navbar-toggler {
    top: .25rem;
    right: 1rem;
}

.navbar .form-control {
    padding: .75rem 1rem;
    border-width: 0;
    border-radius: 0;
}

.form-control-dark {
    color: #fff;
    background-color: rgba(255, 255, 255, .1);
    border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
    border-color: transparent;
    box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

/*
 * Tables
 */
.table {
    &.table-large-padding th,
    &.table-large-padding td, {
        padding: 1rem;
    }

    .actions {
        width: 1px;
        white-space: nowrap;
        text-align: center;

        & > .d-flex > * {
            margin-right: 0.15rem;
            margin-left: 0.15rem;

            &:first-child {
                margin-left: auto;
            }

            &:last-child {
                margin-right: auto;
            }
        }
    }
}

.content-frame-right {
    background: rgba(0, 0, 0, 0.02);
    border-left: 1px solid #DDD;
    margin-top: -1rem;
}
